export enum SuggestionStatus {
  PENDING = 'PENDING',
  DELIVERED = 'DELIVERED',
  LED_TO_ORDER = 'LED_TO_ORDER',
  OBSOLETE_DUE_TO_NEW_ORDER = 'OBSOLETE_DUE_TO_NEW_ORDER',
  ORDER_RECEIVED_INDEPENDENT_OF_REMINDER = 'ORDER_RECEIVED_INDEPENDENT_OF_REMINDER',
  CANCELLED_MANUALLY = 'CANCELLED_MANUALLY',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_REFUNDED = 'ORDER_REFUNDED',
  CANCELLED_DUE_TO_OPT_OUT = 'CANCELLED_DUE_TO_OPT_OUT',
  CANCELLED_UNSENT_CLONE = 'CANCELLED_UNSENT_CLONE',
  CANCELLED_DUE_TO_UNAVAILABILITY = 'CANCELLED_DUE_TO_UNAVAILABILITY ',
  ERROR = 'ERROR',
}
